<template>
  <div>
    <section v-if="processes === null" class="section">
      <div class="container">
      </div>
    </section>

    <section v-else class="section section-top-media">
      <div class="container">
        <div class="row">
          <ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('processType',null)" :class="{'active':params.processType === null}">Todos</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('processType','E')" :class="{'active':params.processType === 'E'}">Pregões</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('processType','D')" :class="{'active':params.processType === 'D'}">Dispensas</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('processType', 'R')" :class="{'active':params.processType === 'R'}">Concorrências</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('processType','L')" :class="{'active':params.processType === 'L'}">Leilões</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('processType','C')" :class="{'active':params.processType === 'C'}">Credenciamentos</a></li>
          </ul>
        </div>
        <div class="row">
          <ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('stageIds',null)" :class="{'active':params.stageIds === null}">Todos</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('stageIds','8')" :class="{'active':params.stageIds === '8'}">Recebendo Propostas</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('stageIds','9')" :class="{'active':params.stageIds === '9'}">Disputa</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('stageIds','10')" :class="{'active':params.stageIds === '10'}">Decisão</a></li>
            <li class="list-inline-item"><a class="categories border d-block text-dark rounded" @click="updateParams('stageIds','11')" :class="{'active':params.stageIds === '11'}">Finalizado</a></li>
          </ul>
        </div>
        <form @submit.stop.prevent="updateParams('searchTerm', params.searchTerm)">
          <div class="row">
            <div class="col-12">
              <div class="subcribe-form pb-4">
                <div class="m-0" style="max-width: 100%;">
                    <div class="form-group mb-0">
                      <input type="text" v-model="params.searchTerm" class="rounded-pill shadow" placeholder="Localizar processo...">
                      <button type="button" @click="updateParams('searchTerm', params.searchTerm)" class="btn btn-pills btn-primary">Buscar</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div v-if="loading" class="col-12 text-center text-primary py-5">
            <div class="fa-2x">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </div>

          <div v-else-if="processes.length === 0" class="col-12 text-center text-primary py-5">
            <h1>Nenhum processo localizado.</h1>
          </div>

          <div
            v-else
            v-for="(process, index) in processes"
            :key="`lot-${index}`"
            class="col-lg-12 col-12 mb-2 pb-2"
          >
            <div class="card event-schedule rounded border pr-2">
              <div class="card-body">
                <div class="media">
                  <ul class="date text-center text-primary mr-3 mb-0 list-unstyled">
                    <li class="day font-weight-bold mb-2">{{ date_to_br_day(process.opening_date) }}</li>
                    <li class="month font-weight-bold">{{ date_to_br_month(process.opening_date).toUpperCase() }}</li>
                  </ul>
                  <div class="media-body content">
                    <div class="row">
                      <div class="col-11 pr-0">
                        <div class="post-meta d-flex justify-content-between">
                          <div>
                            <h5><a href="javascript:void(0)" class="card-title title text-dark"> {{ process.modality }} nº {{ process.bidding_number }}</a></h5>
                          </div>
                          <div>
                            <small class="badge badge-pill" :class="process.bg_class"> {{ process.status }}</small>
                          </div>
                        </div>
                        <p class="text-muted mb-0">
                          <span class="text-muted text-limit-lines-2 mr-2"><b>Objeto:</b> {{ process.object_details }}</span>
                          <span class="text-muted text-limit-lines-2 mr-2"><b>Abertura:</b> {{ date_to_br_in_full(process.opening_date) }} às {{ time_to_br(process.opening_date) }}h</span>
                        </p>
                      </div>
                      <div class="col-1 align-self-center text-right pl-0 pr-3">
                        <a
                          target="_blank"
                          :href="`${urlPlataform}/pesquisa/${process.id}`"
                          class="text-primary text-hover-primary"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </a>

                        <a href="javascript:void(0)" @click="chooseProcess(process.id)" class="text-primary text-hover-primary" v-b-tooltip.hover title="Participar">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                            <polyline xmlns="http://www.w3.org/2000/svg" points="20 6 9 17 4 12"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <b-pagination
                class="justify-content-center mb-0"
                v-model="params.page"
                :total-rows="rows"
                :per-page="params.limit"
                aria-controls="my-table"
                @change="changeCurrentPage"
            ></b-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BiddingSystemProcessService from "@/services/BiddingSystemProcessService";
import date_to_br from "@/utils/date_to_br";
import time_to_br from "@/utils/time_to_br";
import date_to_br_in_full from "@/utils/date_to_br_in_full";
import date_to_br_day from "@/utils/date_to_br_day";
import date_to_br_month from "@/utils/date_to_br_month";
import UrlParameters from "@/utils/urlParameters";

export default {
  data() {
    return {
      loading: false,
      rows: 0,
      processes: [],
      date_to_br: date_to_br,
      time_to_br: time_to_br,
      date_to_br_in_full: date_to_br_in_full,
      date_to_br_day: date_to_br_day,
      date_to_br_month: date_to_br_month,
      params: {
        searchTerm: '',
        processType: null,
        stageIds: null,
        limit: 10,
        offset: 0,
        page: 1,
      },
    }
  },
  async created() {
    UrlParameters.retrieve(this)

    const routeParams = JSON.parse(JSON.stringify(this.$route.query))
    if(typeof routeParams['page'] !== "undefined" && routeParams['page'] !== '') {
      this.updateOffset(routeParams.page)
    }

    await this.getAll()

    this.params.page = routeParams['page']
  },

  computed: {
    urlPlataform() {
      return this.$store.getters['layout/urlPlataform'].replace('app-', 'app2-')
    },
  },

  methods: {
    changeCurrentPage(value) {
      this.updateOffset(value)

      this.updateParams('page', value)
    },

    updateOffset(page) {
      this.params.offset = (page - 1) * this.params.limit
    },

    async chooseProcess(processId) {
      await this.$router.push({ name: 'fornecedor', query: { processId: processId } })
    },

    async getAll() {
      try {
        this.loading = true
        const params = Object.assign({}, this.params)
        delete params['page']

        params['origin'] = window.location.host
        const response = await BiddingSystemProcessService.getAll(params)
        const {data} = response
        this.processes = data.data.data
        this.rows = data.data.pagination.total - this.params.limit
      } finally {
        this.loading = false
      }
    },

    updateParams(column, value) {
      if(column !== 'page') {
        this.params['page'] = 1
        this.updateOffset(1)
      }

      this.params[column] = value

      const params = Object.assign({}, this.params)
      delete params['origin']
      delete params['limit']
      delete params['size']
      delete params['offset']

      UrlParameters.add(this, params);

      this.getAll()
    },
  }
}
</script>
<style scoped>
@media (max-width: 1023px) {
  .section-top-media  {
    padding-top: 90px !important;
  }
}
</style>
