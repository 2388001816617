export default function date_to_br(value) {
    if(value === null) {
        return ''
    }

    let { 0: date, 1: time } = value.split(/T| /);
    if(typeof time === 'undefined') {
        value = `${date} 03:00:00`
    }

    const dateObj = new Date(value);

    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth()+1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');

    return `${day}/${month}/${year}`;
}